@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    #vendor-table-footer>button:first-child {
        @apply py-2 px-3 border rounded-lg
    }

    .navigators {
        @apply h-8 w-8 hover:cursor-pointer ml-8
    }
}