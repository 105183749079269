@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {

    html,
    body {
        @apply p-0 m-0 border-0 h-full overflow-auto
    }

    html, body {
        font-family: "rubik";
    }

    .nav-link-class {
        @apply text-white text-sm py-3 px-1 md:bg-transparent md:p-0 md:mb-0 hover:text-focus-color md:text-white md:hover:text-primary-color
    }

    #loading>div:first-child {
        @apply p-0
    }

    #error-dialog>div:first-child {
        @apply p-0
    }

    #loading, #error-dialog {
        @apply p-0 focus:outline-none
    }
}