@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    #activities-table-footer>button:first-child {
        @apply py-2 px-3 border rounded-lg
    }

    .navigators {
        @apply h-8 w-8 hover:cursor-pointer ml-8
    }

    #activity-form>div:first-child {
        @apply p-0
    }

    #activity-form-header+button {
        @apply focus:outline-none
    }

    #activity-form-header {
        @apply w-full items-center flex
    }

    #activity-form {
        @apply p-0 focus:outline-none
    }

    #activity-form-body {
        font-family: "rubik";
    }

    .date-picker {
        @apply w-11/12 mt-2
    }

    .date-picker>div:first-child {
        @apply items-center flex border border-gray-300 rounded
    }

    .date-picker>div:first-child>input {
        @apply border-none ring-0 ml-2 text-sm text-gray-700
    }

    #activity-weekly+button, #activity-recurrence+button {
        @apply border flex justify-between w-11/12 py-2 px-3 mt-1 rounded-md bg-gray-100 text-sm text-gray-500
    }

    .multi-date-picker {
        @apply w-full
    }

    #delete-activity>div:first-child {
        @apply p-0
    }

    #delete-activity {
        @apply p-0 focus:outline-none
    }
}